import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    GetActiveProfileQuery,
    GetOrder,
    SubmitFeedbackMutation,
    SubmitFeedbackMutationVariables,
} from '@api/generated-types';
import { DataService } from '@core/providers/data/data.service';
import { NotificationService } from '@core/providers/notification/notification.service';
import { Logger } from '@core/utils/logger';
import { untilDestroyed } from '@core/utils/until-destroyed';
import { map, take } from 'rxjs/operators';
import { GET_PROFILE_OVERVIEW } from 'src/app/account/components/account-dashboard/account-dashboard.graphql';
import { SUBMIT_FEEDBACK } from 'src/app/account/components/account-order-detail/account-order-detail.graphql';
import Order = GetOrder.Order;
const log = new Logger('FeedbackWizard');

@Component({
    selector: 'fainin-feedback-wizard',
    templateUrl: './feedback-wizard.component.html',
    styleUrls: ['./feedback-wizard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('fade', [
            state('void', style({ opacity: 0 })),
            transition(':enter', [animate('250ms 250ms')]),
            transition(':leave', [animate('0ms')]),
        ]),
    ],
})
export class FeedbackWizardComponent implements OnInit, OnDestroy {
    @Input() order: Order;
    currentStep = 0;
    step1Rating = 5;
    step2Rating = 5;
    step3Rating = 5;
    averageRating = 5;
    currentProfileId: string | undefined;
    loading: boolean = false;
    isRenter: boolean = false;

    constructor(
        private dataService: DataService,
        private cd: ChangeDetectorRef,
        private notificationService: NotificationService,
    ) {}

    ngOnInit() {
        this.dataService
            .query<GetActiveProfileQuery>(GET_PROFILE_OVERVIEW)
            .pipe(
                map(data => data.activeProfile?.id),
                take(1),
                untilDestroyed(this),
            )
            .subscribe(id => {
                this.currentProfileId = id;
                this.isRenter = this.order.customFields?.renterProfile?.id === id;
                this.cd.markForCheck();
            });
    }

    private calculateAndSetAverage() {
        const avg = (this.step1Rating + this.step2Rating + this.step3Rating) / 3;
        this.averageRating = Math.round(avg * 10) / 10;
    }

    nextStep() {
        this.calculateAndSetAverage();
        this.currentStep++;
    }

    previousStep() {
        this.currentStep--;
        this.cd.markForCheck();
    }

    submitReview(comments: string) {
        this.loading = true;
        this.dataService
            .mutate<SubmitFeedbackMutation, SubmitFeedbackMutationVariables>(SUBMIT_FEEDBACK, {
                input: {
                    orderId: this.order.id,
                    rating: Math.round(this.averageRating),
                    headline: this.order.lines[0].productVariant.name,
                    description: comments ? comments : undefined,
                },
            })
            .subscribe({
                next: result => {
                    this.loading = false;
                    this.cd.markForCheck();
                    window.location.reload();
                },
                error: err => {
                    this.loading = false;
                    this.cd.markForCheck();
                    log.error(err);
                    this.notificationService.error('An error occurred, please try again!');
                },
            });
    }

    /* eslint-disable-next-line */
    ngOnDestroy(): void {}
}
