import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
    faDiscord,
    faFacebook,
    faInstagram,
    faLinkedin,
    faTiktok,
    faTwitter,
    faXTwitter,
    faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import {
    faArrowLeft,
    faArrowRight,
    faBars,
    faBox,
    faBoxOpen,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faClock,
    faCompass,
    faCookieBite,
    faCreditCard,
    faEllipsisH,
    faEllipsisV,
    faExclamationCircle,
    faEye,
    faFilter,
    faHeart,
    faInfoCircle,
    faLock,
    faLink,
    faMapMarkerAlt,
    faMinus,
    faPaperPlane,
    faPeopleCarryBox,
    faPlus,
    faRedo,
    faReply,
    faSadCry,
    faSearch,
    faShare,
    faShippingFast,
    faShoppingCart,
    faSort,
    faStar,
    faTableColumns,
    faTableList,
    faTimes,
    faUser,
    faUserCheck,
    faUserCircle,
    faUserPen,
    faUsers,
    faWindowClose,
    faWandMagicSparkles,
    faCamera,
    faMobile,
    faMobileAlt,
    faNewspaper,
    faVideo,
    faFileLines,
} from '@fortawesome/free-solid-svg-icons';

/**
 * All the icons used in the library are declared here. This allows us to
 * ship only the needed icons so that the bundle does not get bloated.
 */
export function buildIconLibrary(library: FaIconLibrary) {
    library.addIcons(
        faArrowLeft,
        faArrowRight,
        faBars,
        faBox,
        faBoxOpen,
        faCamera,
        faCheck,
        faChevronDown,
        faChevronLeft,
        faChevronRight,
        faClock,
        faCompass,
        faCookieBite,
        faCreditCard,
        faDiscord,
        faXTwitter,
        faEllipsisH,
        faEllipsisV,
        faExclamationCircle,
        faEye,
        faFacebook,
        faFilter,
        faFileLines,
        faHeart,
        faInfoCircle,
        faInstagram,
        faLinkedin,
        faLink,
        faLock,
        faMapMarkerAlt,
        faMapMarkerAlt,
        faMinus,
        faMobileAlt,
        faNewspaper,
        faPaperPlane,
        faPeopleCarryBox,
        faPlus,
        faRedo,
        faReply,
        faSadCry,
        faSearch,
        faShare,
        faShippingFast,
        faShoppingCart,
        faSort,
        faStar,
        faTableColumns,
        faTableList,
        faTiktok,
        faTimes,
        faTwitter,
        faUser,
        faUserCheck,
        faUserCircle,
        faUserCheck,
        faUserPen,
        faUsers,
        faWindowClose,
        faWandMagicSparkles,
        faYoutube,
        farHeart,
        faVideo,
    );
}
