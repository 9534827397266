export default {
    cameraPermissionsHeader: 'Kameraberechtigung',
    cameraPermissionsBody:
        'Bitte erlaube die Kameraberechtigung in den Einstellungen Deines Browsers und aktualisiere die Seite!',
    offerShipping: 'Versand / Lieferung anbieten',
    shippingPrice: 'Versandpreis*',
    shippingNotice: 'Der Versand muss vor dem ersten Tag der Miete erfolgen.',
    hourlyShouldBeLower: 'Stundenpreis sollte geringer sein als der Tagespreis',
    choosePreviewImage: 'Wähle das Vorschaubild mit Doppelklick',
    rentalPriceIsStaggered:
        'Dein Mietpreis wird gestaffelt erhoben, ab Tag X bekommen Mieter:innen die folgenden Rabatte',
    egPrice: 'Bsp. Preis',
    hourlyRental: 'Als Stunden Verleih anbieten',
    makeSureBackground: 'Sorge für einen ruhigen Hintergrund',
    thinkGoodExposure: 'Denk an gute Belichtung',
    photoOnlyRentalOrder: 'Versuche nur das Mietangebot zu fotografieren',
    updateProduct: 'Produkt aktualisieren',
    uploadProduct: 'Produkt hochladen',
    addressInput: 'Adresseingabe',
    noSavedAddresses: 'Keine gespeicherten Adressen',
    savedAddresses: 'Gespeicherte Adressen',
    loading: 'Loading',
    productLocation: 'Produktstandort',
    howPriceIsCalculated: 'Wir empfehlen einen Stundenpreis der 50% des Tagespreises beträgt',
    findOutMoreHere: 'Hier mehr erfahren',
    weCalculateFixedScale: 'Wir berechnen die Wochen und Monatspreise mit einer festgesetzten Staffelung.',
    priceGrad: 'Preisstaffelung',
    graduatedTotalDay1: '1. Tag',
    graduatedTotalDay7: 'Für 7 Tage',
    graduatedTotalDay30: 'Für 30 Tage',
    gradDay: 'Ab Tag {{day}}',
    selectAll: 'Alle auswählen',
    deselectAll: 'Alle abwählen',
    gradPriceFullSentence:
        'Preisstaffelung: Ab Tag 2: -- € | Ab Tag 3: -- € | Ab Tag 4: -- € | Ab Tag 5: -- € | Ab Tag 6: -- € | Ab Tag 7: -- € | Ab Tag 30: -- €',
    gradPriceFullSentenceMobile:
        'Preisstaffelung: Ab Tag 2: -- € | Ab Tag 3: -- € | \nAb Tag 4: -- € | Ab Tag 7: -- € | Ab Tag 30: -- €',
    maxCharacters: 'Maximal 500 Zeichen',
    productDescription: 'Produktbeschreibung',
    galleryImage: 'Galeriebild',
    photoTips: 'Fototipps',
    enterContactName: 'Deine Kontaktname',
    enterStreetName: 'Deine Straße Nr.',
    enterDistrictName: 'Dein Viertel',
    enterZip: '12345*',
    enterCityName: 'Deine Stadt*',
    enterCountryName: 'Geben Sie den Ländernamen ein*',
    addNewLocation: 'Neuen Standort hinzufügen',
    chooseLocation: 'Ort auswählen',
    productInformation: 'Produktinformation',
    title: 'Produktname*',
    uploadPhotos: 'Fotos hochladen',
    fromPhotos: 'aus Fotos auswählen',
    fromCamera: 'mit Kamera aufnehmen',
    category: 'Kategorie*',
    subCategory: 'Unterkategorie*',
    description: 'Beschreibung*',
    rentalPrice: 'Mietpreis',
    price: 'Tagespreis*',
    hourlyPrice: 'Stundenpreis*',
    optionalPrice: 'Preis in €',
    perDay: 'pro Tag',
    perWeek: 'pro Woche',
    perMonth: 'pro Monat',
    purchasePrice: 'Kaufpreis',
    purchaseDate: 'Kaufdatum',
    uploadLocation: 'Standort hochladen',
    save: 'Speichern',
    deleteLocation: 'Standort löschen',
    addLocation: 'Ort hinzufügen',
    date: 'Datum',
    contactName: 'Kontaktname',
    street: 'Straße',
    district: 'Bezirk/Viertel',
    zip: 'Postleitzahl*',
    city: 'Stadt*',
    country: 'Land*',
    saveLocation: 'Ort Speichern',
    getCurrentLocation: 'Aktueller Standort',
    enableLocationTitle: 'Standortnutzung nicht genehmigt',
    locationDisclaimer: 'Der exakte Standort ist nie öffentlich. Lediglich die Stadt ist durch die Suche sichtbar.',
    enableLocation:
        'Bitte genehmige die Nutzung deines Standortes in den Browser Einstellungen um deinen aktuellen Standort zu nutzen',
    enableLocationServiceTitle: 'Standortnutzung deaktiviert!',
    locationUploaded: 'Ihr hochgeladener Standort wird in Ihrer Liste angezeigt',
    locationRetrieved: 'Standort abgerufen',
    locationDeleted: 'Location Entfernt',
    locationListUpdated: 'Die Liste Deiner Upload-Orte wurde aktualisiert',
    productUploaded: 'Your uploaded product will be shown in your listings',
    disclaimer:
        'Diese Informationen sind erst beim Versicherungsfall relevant und können nachträglich verändert und hinzugefügt werden',
    error: {
        required: 'Mindestens ein Bild ist erforderlich.',
        minlength: 'Sie müssen mindestens ein Bild hochladen.',
        maxlength: 'Sie können maximal {{requiredLength}} Bilder hochladen. Derzeit ausgewählt: {{actualLength}}.',
        startBeforeEnd: 'Startdatum muss vor dem Enddatum liegen!',
        errorDeletingLocation: 'Fehler beim Löschen der Location',
        removeFailed: 'Entfernen fehlgeschlagen',
        noAddressProvided: 'Keine Straßenadresse angegeben',
        failedToFindLocation: 'Ihr aktueller Standort konnte nicht gefunden werden',
        failedToCreateUploadLocation: 'Upload-Speicherort konnte nicht erstellt werden',
        failedToLocateAddress: 'Die angegebene Adresse konnte nicht gefunden werden',
        failedToGeolocate: 'Geolokalisierungsdaten konnten nicht abgerufen werden',
        invalidZip: 'Ungültige Postleitzahl für Stadt/Land',
        invalidCityName: 'Ungültiger Stadtname',
        invalidCountryName: 'Ungültiger Ländername',
        attention: 'Achtung!',
        cantVerifyZipTitle: 'Diese Postleitzahl kennen wir nicht',
        cantVerifyZip: 'Bist Du sicher, dass Du mit der aktuellen Eingabe fortfahren möchtest?',
        zipCityDontMatchTitle: 'Achtung!',
        zipCityDontMatchBody: 'Plz und Stadt passen nicht zusammen',
        startEndDates: 'Bitte wähle ein Start- und Enddatum (Beides kann der gleiche Tag sein)',
        invalidCredentials: 'Falsche Angaben. Versuch es gerne nochmal',
        recaptcha: 'Der ReCAPTCHA ist fehlgeschlagen',
        email: 'Bitte gib Deine Lieblings-Mail ein',
        password: 'Bitte gib das passende Passwort ein',
        confirmPassword: 'Achtung! Die zwei Passwörter sind unterschiedlich',
        name: 'Bitte nenne uns Deinen Namen',
        firstName: 'Bitte nenne uns Deinen Vornamen',
        lastName: 'Bitte nenne uns Deinen Nachnamen',
        gender: 'Bitte auswählen',
        businessName: 'Bitte gib Deinen Unternehmensnamen ein',
        vat: 'Bitte gib Deine USt.-Id ein',
        noToken: 'Es wurde leider kein Token gefunden',
        verificationTokenInvalid: 'Der Verifizierungs-Token ist ungültig.',
        verificationTokenExpired: 'Der Verifizierungs-Token ist abgelaufen.',
        title: 'Titel erforderlich (mindestens 3 Zeichen)',
        image: 'Lade ein Bild hoch',
        category: 'Bitte füge eine Kategorie hinzu',
        subCategory: 'Bitte füge eine Subkategorie hinzu',
        description: 'Schreib eine Beschreibung (min. 5 Zeichen)',
        perDay: 'Minimum 1 €',
        perWeek: 'Der Wochenpreis darf nicht 0 sein',
        perMonth: 'Der monatliche Preis darf nicht 0 sein',
        perWeek2: 'Der Wochenpreis muss höher als der Tagespreis sein',
        perMonth2: 'Der Monatspreis muss höher als der Wochen- und Tagespreis sein',
        purchasePrice: 'Minimum 1 €',
        location: 'Keine Standorte gefunden. Bitte erstellen Sie einen neuen Upload-Speicherort.',
        locationName: 'Bitte Standortnamen eingeben',
        districtName: 'Bitte Bezirksnamen eingeben',
        zip: 'Postleitzahl erforderlich',
        cityName: 'Bitte geben Sie den Namen der Stadt ein',
        countryName: 'Bitte geben Sie den Ländernamen ein',
        upload: 'Das Produkt konnte leider nicht erstellt werden.',
    },
    success: {
        addMoreProducts: 'Weitere Artikel vermieten',
        gotoArticle: 'Zum Artikel',
        weLetYouKnow:
            'Mit deinem Artikel hilfst du anderen dabei weniger neu zu kaufen. Vielen Dank dafür! Wenn du noch mehr Dinge hast, die du an andere vermieten würdest, kannst du unten direkt mehr hochladen. Sonst denk daran dir die App zu holen und deine Mails zu checken, damit du mitbekommst wenn jemand etwas von dir mieten möchte.',
        productUploaded: 'Dein Artikel wurde erfolgreich hochgeladen!',
        congrats: 'Herzlichen Glückwunch!',
        share: 'Artikel teilen',
    },
    delete: 'Löschen',
    productSaved: 'Produkt gespeichert!',
    product: 'Produkt',
    upload: 'Produkt inserieren',
    duplicate: 'Duplizieren',
    report: 'Produkt melden',
    create: 'Erstellen',
    community: {
        title: 'Communities',
        info: 'Wähle aus in welchen Communities dein Produkt angezeigt werden soll.',
        placeHolder: 'Verkaufskanal',
        public: 'Öffentlich',
    },
    pricing: {
        title: 'Lege deinen Preis fest',
        subTitle: 'Du kannst ihn jederzeit ändern',
        details: {
            title: 'Preisdetails',
            basePrice: 'Grundpreis',
            serviceFee: 'Mieter Servicegebühr',
            comissionFee: 'Deine Kommissionsgebühr',
            total: 'Der Mieter zahlt',
            earning: 'Du erhältst',
        },
        priceTierAscend: 'Preise sollten aufsteigend sein!',
    },
    advancedPricing: {
        title: 'Preisstaffelung',
        subTitle: 'Passe Rabatte für dein Angebot an.',
        weekend: '2-Tagespreis',
        week: 'Wochenpreis',
        month: 'Monatspreis',
        long: 'Langzeitpreis',
        default: 'Preis',
    },
};
