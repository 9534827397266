export default {
    welcome: 'Willkommen im Pressebereich!',
    welcomeText:
        'In unserem Pressebereich stehen Dir alle relevanten Informationen, Statistiken und Bilder zur Verfügung. Für Interview- oder Presseanfragen sind wir jeder Zeit erreichbar 👇',
    contact: 'Kontakt',
    email: 'E-Mail',
    phone: 'Telefon',
    contactText: 'Ich freue mich von Dir zu hören!',
    pressKit: 'Pressekit & Bildmaterial',
    pressKitText:
        'Hier findest du fainin Bildmaterial inklusive Logos, Fotos, Videos und schriftliche Beschreibungen, mit denen du fainin präsentieren kannst.',
    logos: 'fainin Logos 🪃',
    teamPictures: 'fainin Teambilder 🤽',
    productPictures: 'fainin Produktbilder 📸',
    fullPressKit: 'Vollständiges Pressekit ⭕️',
    videos: 'fainin Videos 🎥',
    infoTexts: 'Beschreibungstexte 📝',
};
