export default {
    cameraPermissionsHeader: 'Camera permissions',
    cameraPermissionsBody: 'Please enable camera permissions in the browser setting and refresh the page!',
    offerShipping: 'Offer shipping/delivery',
    shippingPrice: 'Shipping Price*',
    shippingNotice: 'Shipping must be done before the first day of rental.',
    hourlyShouldBeLower: 'Hourly price should be lower than daily price!',
    choosePreviewImage: 'Choose the preview image with a double tap',
    rentalPriceIsStaggered: 'Your rental price is graduated, from day X the renters will get the following discounts',
    egPrice: 'E.g. Price',
    hourlyRental: 'Offer hourly Rental',
    makeSureBackground: 'Make sure to have a quiet background',
    thinkGoodExposure: 'Keep a good exposure in mind',
    photoOnlyRentalOrder: 'Try to photograph only the rental order',
    updateProduct: 'Update Product',
    uploadProduct: 'Upload Product',
    addressInput: 'Address Input',
    noSavedAddresses: 'No Saved Addresses',
    savedAddresses: 'Saved Addresses',
    loading: 'Loading',
    productLocation: 'Product Location',
    howPriceIsCalculated: 'We suggest the hourly price to be 50% of your daily price',
    findOutMoreHere: 'Find out more here',
    weCalculateFixedScale: 'We calculate the weekly and monthly prices with a fixed scale.',
    priceGrad: 'Graduation of prices',
    graduatedTotalDay1: 'For 1 rental day',
    graduatedTotalDay7: 'For 7 rental days',
    graduatedTotalDay30: 'For 30 rental days',
    gradDay: 'from day {{day}}',
    selectAll: 'Select All',
    deselectAll: 'Deselect All',
    gradPriceFullSentence:
        'Graduation of prices From day 2: -- € | From day 3: -- € | From day 4: -- € | From day 5: -- € | From day 6: -- € | From day 7: -- € | From day 30: -- €',
    gradPriceFullSentenceMobile:
        'Graduation of prices From day 2: -- € | From day 3: -- € | \n From day 4: -- € | From day 7: -- € | From day 30: -- €',
    maxCharacters: 'Max 500 Characters',
    productDescription: 'Product Description',
    galleryImage: 'Gallery Image',
    photoTips: 'Photo Tips',
    enterContactName: 'Enter Contact Name',
    enterStreetName: 'Enter Street Name',
    enterDistrictName: 'Enter District Name',
    enterZip: '12345*',
    enterCityName: 'Enter City Name*',
    enterCountryName: 'Enter Country Name*',
    addNewLocation: 'Add New Location',
    chooseLocation: 'Choose Location',
    productInformation: 'Product Information',
    title: 'Product Title*',
    uploadPhotos: 'Upload Photos',
    fromPhotos: 'Select from photos',
    fromCamera: 'Capture with camera',
    category: 'Category*',
    subCategory: 'Sub-category*',
    description: 'Description*',
    rentalPrice: 'Rental Price',
    price: 'Daily Price*',
    hourlyPrice: 'Hourly Price*',
    optionalPrice: 'Price in €',
    perDay: 'per Day',
    perWeek: 'per Week',
    perMonth: 'per Month',
    purchasePrice: 'Purchase Price',
    purchaseDate: 'Purchase Date',
    uploadLocation: 'Upload Location',
    save: 'Save',
    deleteLocation: 'Delete Location',
    addLocation: 'Add Location',
    date: 'Date',
    contactName: 'Contact Name',
    street: 'Street',
    district: 'District/Neighborhood',
    zip: 'Zip/Postal Code*',
    city: 'City*',
    country: 'Country*',
    saveLocation: 'Save Location',
    getCurrentLocation: 'Current location',
    enableLocationTitle: 'Location Permissions Not Given',
    locationDisclaimer: 'The exact location is never public. Only the city is visible through the search.',
    enableLocation:
        'Please enable your mobile location and permissions in your browser settings to get your current location',
    enableLocationServiceTitle: 'Location Service Disabled!',
    locationUploaded: 'Your uploaded location will be shown in your list',
    locationRetrieved: 'Location Retrieved!',
    locationDeleted: 'Location Deleted!',
    locationListUpdated: 'Your upload locations list has been updated',
    productUploaded: 'Your uploaded product will be shown in your listings',
    disclaimer:
        'This information is only relevant in the case of an insured event and can be changed and added later\n',
    success: {
        addMoreProducts: 'Upload more items',
        gotoArticle: 'Go to item',
        weLetYouKnow:
            "With your article you are helping others to buy less new. Thank you for that! If you have more things you'd like to rent out to others, you can upload them directly below. Otherwise, remember to get the app and check your mails so that you know when someone wants to rent something from you",
        productUploaded: 'Your item has been successfully uploaded.',
        congrats: 'Congratulations!',
        share: 'Share item',
    },
    delete: 'Delete',
    productSaved: 'Product saved!',
    product: 'Product',
    upload: 'Upload Product',
    duplicate: 'Duplicate',
    report: 'Report Product',
    create: 'Create',
    community: {
        title: 'Community',
        info: 'Choose in which community you want your product to be displayed.',
        placeHolder: 'Sales channel',
        public: 'Public',
    },
    pricing: {
        title: 'Set your price',
        subTitle: 'You can change it at any time',
        details: {
            title: 'Price Details',
            basePrice: 'Base Price',
            serviceFee: "Renter's service fee",
            comissionFee: 'Your commission fee',
            total: 'The renter pays',
            earning: 'You receive',
        },
        priceTierAscend: 'Price tiers should be in ascending order!',
    },
    advancedPricing: {
        title: 'Price tiers',
        subTitle: 'Customize discounts for your offer.',
        weekend: '2-Day Price',
        week: 'Weekly Price',
        month: 'Monthly Price',
        long: 'Long-term Price',
        default: 'Price',
    },
    error: {
        required: 'At least one image is required.',
        minlength: 'You must upload at least one image.',
        maxlength: 'You can only upload a maximum of {{requiredLength}} images. Currently selected: {{actualLength}}.',
    },
};
