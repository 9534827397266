export default {
    noCommunity: {
        title: 'No Community Access',
        msg: 'You are not a part of this community, please join the community this product belongs to in order to rent it!',
        okay: 'Okay',
    },
    leavingPage: 'You are about to leave the page...',
    loginToChat: 'You need to be signed in to chat with other users',
    hour: 'Hour',
    shippingAddress: 'Shipping Address',
    shippingReturnAddress: 'Return Address',
    shippingCost: 'Shipping Fee',
    failedClipboard: "Couldn't copy link to clipboard!",
    linkCopiedToClipboard: 'Link copied to clipboard!',
    invalidPeriod: 'Invalid Period',
    requestAlreadyExists:
        "Request already exists. This product's request is pending. Please cancel it to request again.",
    itemUnavailableForPeriod: 'Item unavailable for the requested period!',
    pleaseSignIn: 'Please sign in to request!',
    lessExpensiveDailyPrice: 'Daily price is less expensive and has been applied',
    incl: 'Incl',
    inTotal: 'Total',
    insuranceCoverage: 'Insurance coverage up to €15,000',
    returnTime: 'Return Time*',
    returnTimeOptional: 'Return Time (optional)',
    pickupTime: 'Pick up time*',
    pickupTimeOptional: 'Pick up time (optional)',
    overview: 'Overview',
    availability: 'Availability',
    exactPrice: 'Enter your rental period to see the exact price',
    reviews: 'reviews',
    rentalPrice: 'rental price',
    rentalPeriod: 'Rental period',
    rentNow: 'Rent now',
    days: 'days',
    serviceFee: 'Service fee',
    totalAmount: 'total amount',
    similarProducts: 'Similar rental offers',
    youMightAlsoLike: 'You might also like these products',
    checkAvailibility: 'Check availability',
    inquireNow: 'request now',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    description: 'Description',
    edit: 'Edit Product',
    owner: 'Owner',
    favorite: 'Favorite',
    shipping: {
        notSignedIn: 'You must be logged in to select an address.',
        chooseAddress: 'Choose your address',
        newAddress: 'New address',
    },
    noInsuranceDisclaimer: 'Insurance cannot be provided for this product.',
    cannotChatWithYourself: 'You cannot chat with yourself!',
};
