<fainin-loading *ngIf="loading"></fainin-loading>
<div class="wizard-steps-container">
    <div class="header-container">
        <div class="steps">
            <nz-steps [nzCurrent]="currentStep">
                <nz-step></nz-step>
                <nz-step></nz-step>
                <nz-step></nz-step>
            </nz-steps>
        </div>
    </div>

    <div class="order-card">
        <div>
            <img
                [src]="order.lines[0]?.featuredAsset.preview"
                *ngIf="order.lines[0]?.featuredAsset.preview; else dummyImage" />
            <ng-template #dummyImage>
                <img
                    src="https://dummyimage.com/200x200/5BCBCE/fff&text=ProductImage"
                    alt="{{ 'asset.myProductCard.fallbackProductPreview' | translate }}" />
            </ng-template>
        </div>
        <div class="order-information">
            <p class="text-subline-semi-bold order-info">
                {{ order.lines[0].productVariant.name }}
            </p>
            <p class="paragraph-small order-info">
                {{ 'account.period' | translate }}: {{ order.customFields?.duration.startDate | date : 'dd.MM' }}-{{
                    order.customFields?.duration.endDate | date : 'dd.MM.YYY'
                }}
            </p>
            <div class="paragraph-regular lender">
                <ng-container *ngIf="isRenter">
                    {{ 'account.lender' | translate }}:
                    <a
                        class="paragraph-regular owner"
                        [routerLink]="['/profile', order.customFields?.ownerProfile?.id]"
                        >{{ order.customFields?.ownerProfile?.name }}</a
                    >
                </ng-container>
                <ng-container *ngIf="!isRenter">
                    {{ 'account.renter' | translate }}:
                    <a
                        class="paragraph-regular owner"
                        [routerLink]="['/profile', order.customFields?.renterProfile?.id]"
                        >{{ order.customFields?.renterProfile?.name }}</a
                    >
                </ng-container>
            </div>
        </div>
    </div>

    <fainin-feedback-step1
        @fade
        (ratingChange)="step1Rating = $event"
        [rating]="step1Rating"
        (next)="nextStep()"
        *ngIf="currentStep === 0"></fainin-feedback-step1>
    <fainin-feedback-step2
        @fade
        (ratingChange)="step2Rating = $event"
        [rating]="step2Rating"
        (next)="nextStep()"
        *ngIf="currentStep === 1"></fainin-feedback-step2>
    <fainin-feedback-step3
        @fade
        (ratingChange)="step3Rating = $event"
        [rating]="step3Rating"
        (next)="nextStep()"
        *ngIf="currentStep === 2"></fainin-feedback-step3>
    <fainin-feedback-step4
        @fade
        [orderId]="order.id"
        (submitReview)="submitReview($event)"
        [averageRating]="averageRating"
        *ngIf="currentStep === 3"></fainin-feedback-step4>
</div>
