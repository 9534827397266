export default {
    intro: 'fainin Communities sind ein Platz, an dem man exklusiv mit anderen Teilnehmenden teilen kann und von speziellen Rabatten und einzigartigen Angeboten profitiert. Communities gibt es für Universitäten, Klubs, Unternehmen und verschiedene weitere Organisationen. Möchtest du eine sharing-community an deiner Universität oder deinem Arbeitsplatz? Dann kontaktiere unser Team!',
    requestCommunity:
        'Wenn Du eine Community gründen möchtest, dann schreib uns eine kurze Email an contact@fainin.com.',
    recommended: 'Vorschläge für dich:',
    join: 'Beitreten',
    leave: 'Verlassen',
    show: 'Anzeigen',
    allProducts: 'Alle Produkte',
    requiresLogin: 'Für den Beitritt einer Community musst du angemeldet sein',
    section: {
        info: 'Infos',
        intro: 'Einführung',
        members: 'Mitglieder',
        products: 'Produkte',
        faqs: 'Häufige Fragen',
    },
    allFaqs: 'Alle Fragen',
};
