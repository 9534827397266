<div class="hero small" [style.background-image]="'url(./assets/background/background-desktop.png)'">
    <div class="container text-center">
        <h1 class="mt-5 text-light">{{ 'navigation.press' | translate }}</h1>
        <span class="spacer-accent mx-auto"></span>
        <h3 class="mt-3 text-light">{{ 'content.home.sharingIsOwning' | translate }}</h3>
    </div>
</div>

<div class="container my-5">
    <div class="press-content mt-5">
        <h1>Press @ fainin</h1>
        <h3>{{ 'content.press.welcome' | translate }}</h3>
        <p class="lead">
            {{ 'content.press.welcomeText' | translate }}
        </p>

        <div class="contact-section mt-5">
            <div class="row">
                <div class="col-md-6">
                    <h3>{{ 'content.press.contact' | translate }}</h3>
                    <p class="mb-2">Maximilian Lehmann</p>
                    <p class="mb-2">
                        {{ 'content.press.email' | translate }}: <a href="mailto:max@fainin.com">max@fainin.com</a>
                    </p>
                    <p class="mb-2">
                        {{ 'content.press.phone' | translate }}: <a href="tel:+4917534776656">+49 1753 477656</a>
                    </p>
                    <p class="mt-5">{{ 'content.press.contactText' | translate }}</p>
                </div>
                <div class="col-md-6">
                    <img
                        class="contact-image"
                        ngSrc="assets/image/team/max.webp"
                        priority
                        width="1693"
                        height="2150"
                        alt="Presse Kontakt Person Max" />
                </div>
            </div>
        </div>

        <div class="press-kit-section mt-5">
            <h3>{{ 'content.press.pressKit' | translate }}</h3>
            <p class="lead mb-5">
                {{ 'content.press.pressKitText' | translate }}
            </p>

            <div class="row g-4">
                <!-- Logos Card -->
                <div class="col-md-4 mt-3">
                    <a
                        href="https://drive.google.com/drive/u/0/folders/1gWNy1Kpw8lTqDRMNkOMfAZdtHD-txZcW"
                        target="_blank"
                        class="press-card">
                        <div class="card">
                            <div class="card-body text-center">
                                <i class="icon-logo" style="width: 5rem; height: 5rem"></i>
                            </div>
                        </div>
                        <span class="card-label">{{ 'content.press.logos' | translate }}</span>
                    </a>
                </div>

                <!-- Team Photos Card -->
                <div class="col-md-4 mt-3">
                    <a
                        href="https://drive.google.com/drive/u/0/folders/1-phiCInop0LgDnCSOHYZVOFXFgbXxHP4"
                        target="_blank"
                        class="press-card">
                        <div class="card">
                            <div class="card-body text-center">
                                <fa-icon icon="camera" size="3x"></fa-icon>
                            </div>
                        </div>
                        <span class="card-label">{{ 'content.press.teamPictures' | translate }}</span>
                    </a>
                </div>

                <!-- Product Images Card -->
                <div class="col-md-4 mt-3">
                    <a
                        href="https://drive.google.com/drive/u/1/folders/19zv6TADscPwWFuVgEkdvZUqwyA4jqZCq"
                        target="_blank"
                        class="press-card">
                        <div class="card">
                            <div class="card-body text-center">
                                <fa-icon icon="mobile-alt" size="3x"></fa-icon>
                            </div>
                        </div>
                        <span class="card-label">{{ 'content.press.productPictures' | translate }}</span>
                    </a>
                </div>

                <!-- Press Page Card -->
                <div class="col-md-4 mt-3">
                    <a
                        href="https://drive.google.com/drive/u/2/folders/1zvFTO83lT3mQ3FFMgouktzZKAGvafTsK"
                        target="_blank"
                        class="press-card">
                        <div class="card">
                            <div class="card-body text-center">
                                <fa-icon icon="newspaper" size="3x"></fa-icon>
                            </div>
                        </div>
                        <span class="card-label">{{ 'content.press.fullPressKit' | translate }}</span>
                    </a>
                </div>

                <!-- Videos Card -->
                <div class="col-md-4 mt-3">
                    <a
                        href="https://drive.google.com/drive/u/1/folders/1UBt8z8VDiafN0CesmVZxbRDRuvbwyibG"
                        target="_blank"
                        class="press-card">
                        <div class="card">
                            <div class="card-body text-center">
                                <fa-icon icon="video" size="3x"></fa-icon>
                            </div>
                        </div>
                        <span class="card-label">{{ 'content.press.videos' | translate }}</span>
                    </a>
                </div>

                <!-- Written Descriptions Card -->
                <div class="col-md-4 mt-3">
                    <a
                        href="https://drive.google.com/drive/u/1/folders/1Kj227CJcRxt3TdLtg2B7CyVchUQQIGg-"
                        target="_blank"
                        class="press-card">
                        <div class="card">
                            <div class="card-body text-center">
                                <fa-icon icon="file-lines" size="3x"></fa-icon>
                            </div>
                        </div>
                        <span class="card-label">{{ 'content.press.infoTexts' | translate }}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
