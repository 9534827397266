export default {
    welcome: 'Welcome to our Press area!',
    welcomeText:
        'In our press are you find all relevant Information, statistics and pictures. You can contact us at any time for interview- or pressinquirys 👇',
    contact: 'Contact',
    email: 'Email',
    phone: 'Mobile number',
    contactText: 'We are looking forward to hear from you!',
    pressKit: 'Press kit & content material',
    pressKitText:
        'Here you find fainin image material including logos, photos, videos and written descriptions, with which you can present fainin.',
    logos: 'fainin logos 🪃',
    teamPictures: 'fainin teampictures 🤽',
    productPictures: 'fainin productpictures 📸',
    fullPressKit: 'Full press kit ⭕️',
    videos: 'fainin Videos 🎥',
    infoTexts: 'Information texts 📝',
};
