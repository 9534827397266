export default {
    landingHero: {
        title: 'Miete dir was du brauchst',
        subTitle: 'Direkt in deiner Nähe',
        yourProduct: 'dein Wunschprodukt',
        upload: 'Artikel hochladen',
    },
    requestAdditional: {
        title: 'Mietchancen erhöhen?',
        body: 'Du weißt was du mieten möchtest und brauchst es zu einem bestimmten Zeitpunkt? Dann kann es Sinn ergeben, mehr als eine Anfrage zu verschicken um deine Chancen zu verbessern.',
        button: 'Zur Suche',
    },
    categoryPages: {
        all: 'Alles',
        heroAlt: 'Vorschaubild',
    },
    favWhenLogin: 'Favoriten können erst dauerhaft gespeichert werden, wenn du dich anmeldest',
    tapForProductsNearby: 'Tippe hier um Produkte in Deiner Nähe anzuzeigen',
    safestcommunity: 'Die sicherste sharing community',
    return: 'Zurück',
    filter: 'Filter',
    category: 'Kategorie',
    community: {
        title: 'Community',
        tags: {
            university: 'Universität',
            tech: 'Technik',
            leisure: 'Freizeit',
            business: 'Business',
        },
        requirement: {
            'single-domain': `Eine Email von der Domain: {{domain}}`,
            'any-domain': `Eine Email von einer Domain: {{domain}}`,
            identified: `Eine verifizierte Identität`,
            age: `Ein mindest Alter von: {{age}}`,
        },
    },
    reset: 'Zurücksetzen',
    showProducts: 'Produkte anzeigen',
    copyRight: 'Urheberrecht',
    allRightsReserved: 'ALLE RECHTE VORBEHALTEN',
    sorting: {
        sort: 'Sortieren',
        refresh: 'Aktualisierung',
        priceAsc: 'Preis niedrig zu hoch',
        priceDesc: 'Preis hoch zu niedrig',
        newest: 'Neueste zuerst',
        location: 'Distanz',
    },
    assetGallery: {
        viewAll: 'Alle anzeigen',
        noAssetFound: 'Keine Fotos gefunden',
    },
    collectionBreadCrumbs: {
        home: 'Home',
    },
    menuMobile: {
        navigation: 'Navigation',
        categories: 'Kategorien',
        faininStorefront: 'fainin Storefront',
        close: 'Schließen',
    },
    myProductCard: {
        day: 'Tag',
        perDay: 'pro Tag',
        from: 'Ab',
        communityWithCount_one: '{{count}} Community',
        communityWithCount_other: '{{count}} Communities',
    },
    searchSheet: {
        loadMore: 'Mehr laden',
        noResults: 'Keine Ergebnisse',
        no: 'Keine',
        results: 'Ergebnisse',
    },
    addressModal: {
        okay: 'Okay',
    },
    cartContents: {
        orderAdjustments: 'Bestellungsanpassungen',
        shipping: 'Versand',
        total: 'Gesamt',
    },
    soonLabel: {
        comingSoon: 'Demnächst Verfügbar',
    },
    userComponent: {
        awarded: 'Verliehen',
        onLoan: 'Ausgeliehen',
    },
    locationPermission: {
        locationDisclaimer:
            'Deine genaue Adresse wird anderen Nutzern nicht angezeigt. Diese können lediglich den Straßennamen bei der Suche sehen.',
        location: 'Deine Stadt',
        locationProductUpload: 'Straße & Hausnr.',
        allowLocation: 'Standortnutzung Erlauben',
        useExactLocation: 'Exakten Standort nutzen',
        required: 'Wir benötigen deine Berechtigung, um deinen Standort einmalig abzufragen. ',
        allowLocationPointOne: 'Klicke auf <b>Erlauben</b> - und warte auf das folgende <b>Pop-up</b>',
        allowLocationPointTwo:
            '<b>Erlaube fainin</b> den Zugriff auf deinen Standort <b>& mache weiter wo du warst</b>',
        givePermission: 'Erlauben',
        missingLocation: 'Fehlende Berechtigung',
        missingLocationPointOne: 'Öffne deine <b>Browser Einstellungen</b>',
        missingLocationPointTwo:
            '<b>Erlaube den Zugriff</b> auf deinen  Standort Einstellungen <b>& gehe zurück in die App</b>',
        missingLocationPointThree:
            'Klicke auf <b>Nochmal probieren</b> - der Vorgang wird erneut geladen &  fortgesetzt',
        notNow: 'Nicht Jetzt',
        notGiven: 'Standort-Berechtigung fehlt!',
        checkInputTryAgain: 'Bitte überprüfe deine Eingabe und versuche es erneut!',
        tryAgain: 'Nochmal Probieren',
        zipCity: 'PLZ Stadt...*',
    },
    germany: 'Deutschland',
    currentLocation: 'Standort',
    searchbar: 'Suche nach Mietartikeln',
    NoLocation: 'Kein Standort',
};
