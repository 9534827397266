import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FontAwesomeCoreModule } from '@core/fontAwesome-core.module';
import { HasMetadata, IMetaPageInfo } from '@core/seo/site-meta.types';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'fainin-press',
    standalone: true,
    imports: [CommonModule, TranslateModule, FontAwesomeCoreModule, NgOptimizedImage],
    templateUrl: './press.component.html',
    styleUrls: ['./press.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PressComponent implements HasMetadata {
    pageMetadata: Observable<IMetaPageInfo | undefined> = of({
        localized: true,
        title: 'meta.press.title',
        description: 'meta.press.description',
        keywords: 'fainin, logo, presse, assets, pressemitteilungen, logos, bilder, press',
    });
}
